var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "manual-detail row" },
    [
      _c("div", { staticClass: "col-12 vod-top-layer" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _c("div", { staticClass: "col-10 vod-label-layer-vod" }, [
        _c(
          "div",
          { staticClass: "player-center" },
          [
            _c("video-player", {
              ref: "videoPlayer",
              attrs: { options: _vm.playerOptions },
              on: {
                playing: _vm.onPlayerStart,
                play: _vm.onPlay,
                pause: _vm.onPause,
                ended: function ($event) {
                  return _vm.onPlayerEnded($event)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "col-2 vod-label-layer" }, [
        _vm.isEduEnd
          ? _c("div", { staticClass: "vod-label-div" }, [
              _vm._v(
                _vm._s(_vm.$label("LBL0002958")) +
                  " " +
                  _vm._s(_vm.isEduEndDate)
              ),
            ])
          : _vm._e(),
        !_vm.isEduEnd
          ? _c("div", { staticClass: "vod-label-div" }, [
              _vm._v(
                _vm._s(_vm.$label("LBL0002959")) +
                  " " +
                  _vm._s(_vm.vodTotalDuration)
              ),
            ])
          : _vm._e(),
        !_vm.isEduEnd
          ? _c("div", { staticClass: "vod-label-div" }, [
              _vm._v(
                _vm._s(_vm.$label("LBL0002960")) +
                  " " +
                  _vm._s(_vm.vodPlayDuration)
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "vod-label-div" },
          [
            _vm.isFirstPlay && !_vm.isPlay && !_vm.isEduEnd
              ? _c("c-btn", {
                  attrs: {
                    btnclass: "full-width",
                    label: "LBL0002961",
                    icon: "skip_next",
                    color: "orange",
                  },
                  on: { btnClicked: _vm.onPlayerPlayFirst },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vod-label-div" },
          [
            !_vm.isEduEnd
              ? _c("c-btn", {
                  attrs: {
                    btnclass: "full-width",
                    label: _vm.playLabel,
                    icon: _vm.playIcon,
                    color: _vm.playColor,
                  },
                  on: { btnClicked: _vm.onPlayerPlay },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vod-label-div" },
          [
            _vm.isEnd && !_vm.isPlay && !_vm.isEduEnd && !_vm.isEduQuestionPass
              ? _c("c-btn", {
                  attrs: {
                    btnclass: "full-width",
                    label: "LBL0002962",
                    icon: "task_alt",
                    color: "teal-6",
                  },
                  on: { btnClicked: _vm.questionExplanation },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm.isEnd && !_vm.isPlay && !_vm.isEduEnd && _vm.isEduQuestionPass
          ? _c(
              "div",
              { staticClass: "container signature-border vod-label-div" },
              [
                _c(
                  "div",
                  { staticClass: "row items-center all-pointer-events" },
                  [
                    _c(
                      "b",
                      [
                        _c("font", { attrs: { id: "signatureTitle" } }, [
                          _vm._v("  " + _vm._s(_vm.$label("LBL0002921"))),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
                _c("VueSignaturePad", {
                  ref: "signaturePad",
                  attrs: {
                    id: "signature",
                    width: "90%",
                    height: "200px",
                    options: _vm.options,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _c(
              "q-btn-group",
              { attrs: { outline: "" } },
              [
                _vm.isEnd &&
                !_vm.isPlay &&
                !_vm.isEduEnd &&
                _vm.isEduQuestionPass
                  ? _c("c-btn", {
                      attrs: {
                        label: "LBL0002952",
                        icon: "remove",
                        color: "red-6",
                      },
                      on: { btnClicked: _vm.onSighRemove },
                    })
                  : _vm._e(),
                _vm.isEnd &&
                !_vm.isPlay &&
                !_vm.isEduEnd &&
                _vm.isEduQuestionPass
                  ? _c("c-btn", {
                      attrs: {
                        label: "LBL0002953",
                        icon: "task_alt",
                        color: "teal-6",
                      },
                      on: { btnClicked: _vm.eduComplete },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "vod-label-div-text" }, [
          _vm._v(" " + _vm._s(_vm.$label("LBL0002954")) + " "),
          _c("br"),
          _vm._v(" " + _vm._s(_vm.$label("LBL0002955")) + " "),
          _c("br"),
          _vm._v(" " + _vm._s(_vm.$label("LBL0002956")) + " "),
          _c("br"),
          _vm._v(" " + _vm._s(_vm.$label("LBL0002957")) + " "),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }